var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "OrganismInfoPopup",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.open, expression: "open" }
      ]
    },
    [
      _c(
        "div",
        { staticClass: "close-i", on: { click: _vm.handleClose } },
        [_c("AtomIcon", { attrs: { icon: "xmark" } })],
        1
      ),
      _c("div", { staticClass: "row info-i" }, [
        _c("div", { staticClass: "col col-12" }, [
          _c("h4", [_vm._v("Easy "), _c("b", [_vm._v("Experience")])])
        ]),
        _c("div", { staticClass: "col col-xs-12 col-6" }, [
          !_vm.isFloorView
            ? _c("div", { staticClass: "content-info" }, [
                _c("div", { staticClass: "icon-atom-custom" }, [
                  _c("img", {
                    staticClass: "mouse-i",
                    attrs: {
                      src: _vm.cdnImage("/images/mouse-i.svg"),
                      alt: "Mouse"
                    }
                  }),
                  _c("p", [
                    _vm._v(
                      "Press and drag to explore or scroll down for more information"
                    )
                  ])
                ]),
                _c("div", { staticClass: "icon-atom-custom" }, [
                  _c("img", {
                    staticClass: "hand-i",
                    attrs: {
                      src: _vm.cdnImage("/images/hand-i.svg"),
                      alt: "Hand"
                    }
                  }),
                  _c("p", [
                    _vm._v(
                      "Click/Tap on the office space to see more information."
                    )
                  ])
                ]),
                _c("div", { staticClass: "icon-atom-custom" }, [
                  _c("img", {
                    staticClass: "3d-i",
                    attrs: { src: _vm.cdnImage("/images/3d-i.svg"), alt: "3D" }
                  }),
                  _c("p", [_vm._v("Press this button to change in 3D view")])
                ]),
                _c("div", { staticClass: "icon-atom-custom" }, [
                  _c("img", {
                    staticClass: "first-person-i",
                    attrs: {
                      src: _vm.cdnImage("/images/first-person-i.svg"),
                      alt: "First Person"
                    }
                  }),
                  _c("p", [
                    _vm._v(
                      "Use this button to enter in Virtual Tour and click once more to exit"
                    )
                  ])
                ])
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "col col-xs-12 col-6" }, [
          !_vm.isFloorView
            ? _c("div", { staticClass: "content-info" }, [
                _c("div", { staticClass: "icon-atom-custom" }, [
                  _c("img", {
                    staticClass: "menu-i",
                    attrs: {
                      src: _vm.cdnImage("/images/menu-i.svg"),
                      alt: "Menu"
                    }
                  }),
                  _c("p", [_vm._v("Use the menu to navigate through projects")])
                ]),
                _c("div", { staticClass: "icon-atom-custom" }, [
                  _c("img", {
                    staticClass: "home-i",
                    attrs: {
                      src: _vm.cdnImage("/images/home-i.svg"),
                      alt: "Home"
                    }
                  }),
                  _c("p", [
                    _vm._v(
                      "Click/Tap on this button to see the details the office space you selected"
                    )
                  ])
                ]),
                _c("div", { staticClass: "icon-atom-custom" }, [
                  _c("img", {
                    staticClass: "filter-i",
                    attrs: {
                      src: _vm.cdnImage("/images/filter-i.svg"),
                      alt: "Filter"
                    }
                  }),
                  _c("p", [
                    _vm._v(
                      "Use filters to filter and explore other office spaces"
                    )
                  ])
                ])
              ])
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }