<template>
  <div
    id="header-project"
    ref="menu"
    :class="{ 'organism-menu-bar-project': true, scrolled: isScrolled }"
  >
    <div class="row align-items-center menu-bar-content">
      <div class="block-element">
        <!--        <div v-if="showBackButton" class="inline-elements">-->
        <!--          <router-link tag="a" :to="{name: 'SpecificProject', params: {projectId: activeProject}}" class="back-link">-->
        <!--            <AtomIcon icon="angle-left" :size="32" color="#000" />-->
        <!--          </router-link>-->
        <!--        </div>-->
        <div class="inline-elements">
          <MoleculeMenuTrigger />
        </div>
        <!--        <div v-if="showProjectSelect && projectOptions.length > 1" class="inline-elements hidden-xs">-->
        <!--          <AtomDropdown :options="projectOptions" :selected="currentProject" title-label="Change Project" @change="handleProjectChange" />-->
        <!--        </div>-->
        <router-link
          tag="div"
          :to="{ name: !getSkipBuildingInit ? 'Home' : 'Space' }"
          class="inline-elements"
        >
          <AtomLogo />
        </router-link>
      </div>
      <div class="block-element">
        <div class="inline-elements">
          <div class="menu">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtomLogo from "../atoms/common/AtomLogo";
import MoleculeMenuTrigger from "../molecules/MoleculeMenuTrigger";
import { isMobile, isTablet } from "../../helpers/mobile/DeviceType";
import {
  convertUCWordsOrCamelCaseToDashCase,
  smoothScrollToTargetId,
} from "../../helpers/util";

export default {
  name: "OrganismMenuBarProject",
  components: { MoleculeMenuTrigger, AtomLogo },
  data() {
    return {
      isScrolled: false,
    };
  },
  mounted() {
    document.addEventListener("scroll", this.initScrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.initScrollListener);
  },
  methods: {
    initScrollListener() {
      this.isScrolled = window.scrollY > 40;
    },
    handleProjectChange(option) {
      if (this.currentProject === option.value) return;
      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(
        projectConstants.withNamespace(
          projectConstants.action.CHANGE_ACTIVE_PROJECT
        ),
        option.value
      );
      this.$router.push({
        name: "SpecificProject",
        params: { projectId: option.value },
      });
    },
    handleScrollToTop() {
      this.scrollTo3DSection();
      if (isMobile() || isTablet()) {
        const base = this.$store.getters.constants.base;
        this.$store.dispatch(
          base.withNamespace(base.action.TOGGLE_MOBILE_AVAILABILITY_MENU)
        );
        this.$store.dispatch(
          base.withNamespace(base.action.CHANGE_SIDEBAR_STATE),
          false
        );
        const requestOffer = this.$store.getters.constants.requestOffer;
        this.$store.dispatch(
          requestOffer.withNamespace(
            requestOffer.action.CHANGE_CART_SPACES_STATE
          ),
          false
        );
      }
    },
    scrollTo3DSection() {
      const sections = this.sections;
      const buildingSection = this.sections.find((s) => s.type === "Building");
      if (buildingSection) {
        smoothScrollToTargetId(`#${buildingSection.hashAnchor}`);
      }
    },
  },
  computed: {
    currentProject() {
      const currentProject = this.$store.state.project.projects.find((item) => {
        return item.slug === this.$store.state.project.activeProject;
      });
      if (!currentProject) {
        return "";
      }
      return currentProject.slug;
    },
    projectOptions() {
      const projects = [...this.$store.state.project.projects];
      return projects.map((project) => {
        return { label: project.name, value: project.slug };
      });
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    showBackButton() {
      const { name } = this.$route;
      return name === "SpecificSpace";
    },
    showProjectSelect() {
      const { name } = this.$route;
      return name !== "SpecificSpace";
    },
    sections() {
      const { page } = this.$route.meta;
      return page ? page.sections : [];
    },
    getSkipBuildingInit() {
      return this.$store.getters.getSkipBuildingInit;
    },
  },
};
</script>

<style lang="scss">
.organism-menu-bar-project {
  position: fixed;
  top: 0.75rem;
  left: 1.25rem;
  border-radius: 6.25rem;
  padding: 0.5rem 1.5rem 0.5rem 0.5rem;
  z-index: 9999;
  pointer-events: none;
  display: flex;
  background: $white;
  @media only screen and (max-width: 767px) {
    padding: 0.188rem 1.25rem 0.188rem 0.5rem;
    top: 0.625rem;
    left: 0.625rem;
  }
  .menu-bar-content {
    pointer-events: all;
  }
  .rest-fix {
    pointer-events: none;
    flex: 1;
  }
  &.scrolled {
    background: $white;
    box-shadow: 0 0 0.063rem 0.063rem #f7f7f7;
  }
  .atom-dropdown {
    .selected-value {
      padding: 0.625rem 0.938rem;
      background: #f7f7f7;
      .title-label {
        opacity: 0.4;
        @include font-main(0.75rem, $blackOpacity08, $regular, 0.875rem);
      }
      p {
        &.value {
          letter-spacing: 0.05em;
          text-transform: uppercase;
          @include font-main(0.875rem, $black, $regular, 1.063rem);
        }
      }
    }
  }
  .inline-elements {
    display: inline-block;
    vertical-align: middle;
  }
  .back-link {
    width: 3.25rem;
    height: 3.25rem;
    border: 0.063rem solid transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media only screen and (max-width: 767px) {
      width: 3.25rem;
      height: 3.25rem;
    }
    .icon {
      font-size: 1.875rem !important;
      color: $black !important;
      @media only screen and (max-width: 767px) {
        font-size: 1.375rem !important;
      }
    }
  }
  .atom-logo {
    margin: 0.25rem 1.563rem 0 0.625rem;
    @media only screen and (max-width: 767px) {
      margin: 0 0 0 1.25rem;
    }
    img {
      display: block;
      max-height: 3rem;
      @media only screen and (max-width: 767px) {
        max-height: 3.25rem;
      }
    }
  }
  .link-atom {
    background: rgba(58, 189, 89, 0.2);
    border-color: transparent;
    padding: 0.875rem 1.25rem;
    margin-right: 0.625rem;
    @media only screen and (max-width: 767px) {
      padding: 0.5rem 0.625rem;
      max-width: 9.063rem;
    }
    span {
      @include font-main(0.875rem, #3abd59, $medium, 1.063rem);
      @media only screen and (max-width: 767px) {
        @include font-main(0.75rem, #3abd59, $medium, 0.938rem);
      }
    }
    &:hover {
      background: #3abd59;
      border-color: transparent;
      @media only screen and (max-width: 1200px) {
        background: rgba(58, 189, 89, 0.2);
      }
      span {
        color: $white;
        @media only screen and (max-width: 1200px) {
          color: #3abd59;
        }
      }
    }
    &.active {
      background: #3abd59 !important;
      border-color: transparent !important;
      span {
        color: $white !important;
      }
    }
  }
  .menu {
    margin-top: -0.375rem;
    .sidebar-link {
      letter-spacing: 0.04em;
      text-transform: uppercase;
      text-decoration: none;
      margin: 0 0.125rem;
      height: 2.5rem;
      padding: 0.5rem;
      border-radius: 0.188rem;
      transition: all 0.2s ease-in-out 0s;
      @include font-main(0.875rem, rgba(2, 2, 2, 1), $medium, 1.063rem);
      &:hover,
      &.active {
        color: white;
        background-color: $main-color;
      }
    }
  }
}
.atom-dropdown {
  .menu-options {
    padding: 0.25rem 0 0 !important;
    .option {
      background: #f7f7f7;
      padding: 0.625rem 0.938rem;
      margin-bottom: 0.125rem;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      @include font-main(0.875rem, $black, $regular, 1.063rem);
      &:hover {
        background: rgba(134, 123, 194, 0.2);
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
