<template>
    <OrganismInfoPopup v-show="open">
      <div class="close-i" @click="handleClose">
        <AtomIcon icon="xmark" />
      </div>
      <div class="row info-i">
        <div class="col col-12">
          <h4>Easy <b>Experience</b></h4>
        </div>
        <div class="col col-xs-12 col-6">
          <div class="content-info" v-if="!isFloorView">
            <div class="icon-atom-custom">
              <img
                class="mouse-i"
                :src="cdnImage('/images/mouse-i.svg')"
                alt="Mouse"
              />
              <p>Press and drag to explore or scroll down for more information</p>
            </div>
            <div class="icon-atom-custom">
              <img
                  class="hand-i"
                  :src="cdnImage('/images/hand-i.svg')"
                  alt="Hand"
              />
              <p>Click/Tap on the office space to see more information.</p>
            </div>
            <div class="icon-atom-custom">
              <img
                  class="3d-i"
                  :src="cdnImage('/images/3d-i.svg')"
                  alt="3D"
              />
              <p>Press this button to change in 3D view</p>
            </div>
            <div class="icon-atom-custom">
              <img
                  class="first-person-i"
                  :src="cdnImage('/images/first-person-i.svg')"
                  alt="First Person"
              />
              <p>Use this button to enter in Virtual Tour and click once more to exit</p>
            </div>
          </div>
        </div>
        <div class="col col-xs-12 col-6">
          <div class="content-info" v-if="!isFloorView">
            <div class="icon-atom-custom">
              <img
                  class="menu-i"
                  :src="cdnImage('/images/menu-i.svg')"
                  alt="Menu"
              />
              <p>Use the menu to navigate through projects</p>
            </div>
            <div class="icon-atom-custom">
              <img
                class="home-i"
                :src="cdnImage('/images/home-i.svg')"
                alt="Home"
              />
              <p>Click/Tap on this button to see the details the office space you selected</p>
            </div>
            <div class="icon-atom-custom">
              <img
                class="filter-i"
                :src="cdnImage('/images/filter-i.svg')"
                alt="Filter"
              />
              <p>Use filters to filter and explore other office spaces</p>
            </div>
          </div>
        </div>
      </div>
    </OrganismInfoPopup>
</template>

<script>
import AtomIcon from "@/components/atoms/common/AtomIcon.vue";
import OrganismInfoPopup from "@/components/organisms/OrganismInfoPopup.vue";
import { CurrentView } from "@/components/organisms/project/building/store";

export default {
  name: "OrganismTutorial",
  components: {OrganismInfoPopup, AtomIcon},
  mounted() {
    if(!this.$refs.building || !this.$refs.building.buildingStore) return;
    this.$refs.building.buildingStore.subscribe((payload, state) => {
      this.isFloorView = state.currentView === CurrentView.FLOOR;
    });
  },
  data() {
    return {
      isFloorView: false,
    }
  },
  computed: {
    open() {
      return this.$store.state?.project?.infoBox?.open || false;
    }
  },
  methods: {
    cdnImage(image){
      return this.$store.getters.cdnBase + image;
    },
    handleClose() {
      const projectConstants = this.$store.getters.constants.project;
      this.$store.dispatch(
        projectConstants.withNamespace(
          projectConstants.action.TOGGLE_INFO_BOX
        ),
        {
          open: false,
        }
      );
    },
  }
}
</script>

<style lang="scss" scoped>

</style>