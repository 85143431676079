<template>
  <div :class="{'organism-info': true, 'open': isOpen}">
    <slot></slot>
  </div>
</template>
<script>
import {baseConstants} from "@/store/modules";

export default {
  name: "OrganismInfoPopup",
  computed: {
    isOpen() {
      return this.$store.state[baseConstants.namespace].infoOpen;
    }
  }
}
</script>

<style lang="scss">
.organism-info {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  background: rgba(0, 0, 0, 0.8);
  padding: 1.25rem 2.5rem 2rem;
  width: 38.75rem;
  max-width: 100%;
  transform: translate(-50%, -50%);
  z-index: 99999999;
  transition: all 0.5s ease-in-out 0s;
  @media only screen and (max-width: 767px) {
    max-width: 90%;
    width: 90%;
    padding: 25px 20px;
    max-height: 80%;
    overflow: auto;
    top: 80px;
    transform: translateX(-50%);
  }
  &.open {
    display: block;
  }
  .close-i {
    position: absolute;
    right: 1.875rem;
    top: 1.25rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 2.5rem;
    background: rgba(255, 255, 255, 0.04);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 9;
    transition: all 0.2s ease-in-out 0s;
    @media only screen and (max-width: 767px) {
      right: 20px;
      top: 20px;
      width: 40px;
      height: 40px;
      border-radius: 40px;
    }
    &:hover {
      background: $black;
    }
    .icon {
      font-size: 1.25rem !important;
      color: $white !important;
      @media only screen and (max-width: 767px) {
        font-size: 20px !important;
      }
    }
  }
  .content-info {
    @media only screen and (max-width: 767px) {
      margin-top: 20px;
    }
  }
  .row {
    margin: 0 -1.25rem;
    @media only screen and (max-width: 767px) {
      margin: 0 -10px;
    }
    .col {
      padding: 0 1.25rem;
      @media only screen and (max-width: 767px) {
        padding: 0 10px;
      }
    }
  }
  h4 {
    font-size: 2rem;
    line-height: 2.375rem;
    color: $white;
    margin-bottom: 25px;
    @media only screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 0;
    }
    b {
      font-size: 2rem;
      line-height: 2.375rem;
      @media only screen and (max-width: 767px) {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }
  h6 {
    color: $white;
    font-weight: $medium;
    font-size: 1rem;
    line-height: 1.188rem;
    margin-bottom: 1.25rem;
    @media only screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 20px;
    }
  }
  p {
    font-weight: $light;
    font-size: 0.875rem;
    line-height: 1.063rem;
    color: $white;
    @media only screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 17px;
    }
  }
  .icon-atom-custom {
    padding-left: 2.5rem;
    position: relative;
    margin-bottom: 1rem;
    @media only screen and (max-width: 767px) {
      padding-left: 40px;
      margin-bottom: 16px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    img {
      position: absolute;
      left: 0;
      top: 0.125rem;
      width: 1.313rem;
      @media only screen and (max-width: 767px) {
        top: 2px;
        width: 21px;
      }
      &.orientation-i, &.esc-i {
        top: -0.125rem;
        @media only screen and (max-width: 767px) {
          top: -2px;
        }
      }
      &.mouse-i, &.cursor-i, &.hand-i, &.circle-plus-i, &.home-i {
        width: 1rem;
        @media only screen and (max-width: 767px) {
          width: 16px;
        }
      }
      &.arrow-left-i {
        width: 0.8rem;
        @media only screen and (max-width: 767px) {
          width: 13px;
        }
      }
      &.fitout-i {
        width: 1rem;
        margin-top: -0.15rem;
        @media only screen and (max-width: 767px) {
          width: 16px;
          margin-top: -2.5px;
        }
      }
    }
  }
  .icon-bottom-custom {
    p {
      margin-bottom: 1rem;
      @media only screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }
    .row-images {
      .image {
        display: inline-block;
        vertical-align: middle;
      }
      p {
        display: inline-block;
        vertical-align: middle;
        margin: -0.438rem 1rem 0;
        @media only screen and (max-width: 767px) {
          margin: -7px 16px 0;
        }
      }
      img {
        width: 3.5rem;
        @media only screen and (max-width: 767px) {
          width: 56px;
        }
      }
    }
  }
}
</style>