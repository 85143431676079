var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-template page-wrapper" },
    [
      _c("router-view"),
      _c("OrganismSidebar", [
        _c(
          "div",
          { staticClass: "menu" },
          [
            _vm.projects.length > 1
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "subtitle" }, [
                      _vm._v("Portfolio")
                    ]),
                    _vm._l(_vm.projects, function(project, i) {
                      return _c(
                        "a",
                        {
                          key: i,
                          staticClass: "sidebar-link",
                          attrs: {
                            href: _vm.portfolioPageDisabled
                              ? "/" + project.slug
                              : "/project/" + project.slug
                          }
                        },
                        [_vm._v(_vm._s(project.name))]
                      )
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm.projects.length > 1
              ? _c("div", { staticClass: "separator" })
              : _vm._e(),
            _c(
              "router-link",
              {
                staticClass: "sidebar-link",
                attrs: {
                  "active-class": "active",
                  tag: "a",
                  to: { name: !_vm.getSkipBuildingInit ? "Home" : "Space" }
                }
              },
              [_vm._v("Home")]
            ),
            _vm.containsSpaceSlug
              ? _c(
                  "div",
                  { staticClass: "hidden-lg" },
                  _vm._l(_vm.sections, function(section, i) {
                    return _vm.allowSection(section)
                      ? _c(
                          "router-link",
                          {
                            key: i,
                            staticClass: "sidebar-link",
                            attrs: {
                              "active-class": "active",
                              tag: "a",
                              to: {
                                name: "SpecificSpace",
                                params: {
                                  projectId: _vm.activeProject,
                                  spaceId: _vm.activeSpace
                                },
                                hash: "#" + section.hashAnchor
                              }
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.hashChecker("#" + section.hashAnchor)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(section.menuLabel) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  }),
                  1
                )
              : _c(
                  "div",
                  { staticClass: "hidden-lg" },
                  _vm._l(_vm.sections, function(section, i) {
                    return _vm.allowSection(section)
                      ? _c(
                          "router-link",
                          {
                            key: i,
                            staticClass: "sidebar-link",
                            attrs: {
                              "active-class": "active",
                              tag: "a",
                              to: {
                                name: _vm.pageName,
                                params: { projectId: _vm.activeProject },
                                hash: "#" + section.hashAnchor
                              }
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.hashChecker("#" + section.hashAnchor)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(section.menuLabel) +
                                "\n        "
                            )
                          ]
                        )
                      : _vm._e()
                  }),
                  1
                ),
            _vm._l(_vm.staticPages, function(page, i) {
              return _c(
                "router-link",
                {
                  key: i,
                  staticClass: "sidebar-link",
                  attrs: {
                    "active-class": "active",
                    tag: "a",
                    to: { name: page.name, params: {} }
                  }
                },
                [_vm._v(_vm._s(page.menuLabel || page.name))]
              )
            }),
            _vm._l(_vm.projectSpecificStaticPages, function(page, i) {
              return _c(
                "div",
                { key: i },
                [
                  !_vm.externalLink(page.path)
                    ? _c(
                        "router-link",
                        {
                          staticClass: "sidebar-link",
                          attrs: {
                            "active-class": "active",
                            tag: "a",
                            to: {
                              name: page.name,
                              params: { projectId: _vm.activeProject }
                            }
                          }
                        },
                        [_vm._v(_vm._s(page.menuLabel || page.name))]
                      )
                    : _c(
                        "a",
                        {
                          staticClass: "sidebar-link",
                          attrs: { href: page.path }
                        },
                        [_vm._v(_vm._s(page.menuLabel || page.name))]
                      )
                ],
                1
              )
            })
          ],
          2
        ),
        _vm.facebookUrl || _vm.instagramUrl || _vm.linkedinUrl
          ? _c("div", { staticClass: "social-links" }, [
              _c(
                "a",
                { attrs: { href: _vm.instagramUrl } },
                [
                  _c("AtomIcon", {
                    attrs: { icon: "instagram", size: 24, color: "#000" }
                  })
                ],
                1
              ),
              _c(
                "a",
                { attrs: { href: _vm.linkedinUrl } },
                [
                  _c("AtomIcon", {
                    attrs: { icon: "linkedin-squared", size: 24, color: "#000" }
                  })
                ],
                1
              ),
              _c(
                "a",
                { attrs: { href: _vm.facebookUrl } },
                [
                  _c("AtomIcon", {
                    attrs: { icon: "facebook-squared", size: 24, color: "#000" }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ]),
      _c("OrganismMenuBarProject", [
        _vm.containsSpaceSlug
          ? _c(
              "div",
              { staticClass: "hidden-xs" },
              _vm._l(_vm.sections, function(section, i) {
                return _vm.allowSection(section)
                  ? _c(
                      "router-link",
                      {
                        key: i,
                        staticClass: "sidebar-link navigation-link",
                        attrs: {
                          "active-class": "active",
                          tag: "a",
                          to: {
                            name: "SpecificSpace",
                            params: {
                              projectId: _vm.activeProject,
                              spaceId: _vm.activeSpace
                            },
                            hash: "#" + section.hashAnchor
                          }
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.hashChecker("#" + section.hashAnchor)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(section.menuLabel) + "\n      "
                        )
                      ]
                    )
                  : _vm._e()
              }),
              1
            )
          : _c(
              "div",
              { staticClass: "hidden-xs" },
              _vm._l(_vm.sections, function(section, i) {
                return _vm.allowSection(section)
                  ? _c(
                      "router-link",
                      {
                        key: i,
                        staticClass: "sidebar-link navigation-link",
                        attrs: {
                          "active-class": "active",
                          tag: "a",
                          to: {
                            name: _vm.pageName,
                            params: { projectId: _vm.activeProject },
                            hash: "#" + section.hashAnchor
                          }
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.hashChecker("#" + section.hashAnchor)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(section.menuLabel) + "\n      "
                        )
                      ]
                    )
                  : _vm._e()
              }),
              1
            )
      ]),
      _c("OrganismTutorial")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }