<template>
  <div class="project-template page-wrapper">
    <router-view></router-view>
    <OrganismSidebar>
      <div class="menu">
        <div v-if="projects.length > 1">
          <div class="subtitle">Portfolio</div>
          <a
            v-for="(project, i) in projects"
            :key="i"
            class="sidebar-link"
            :href="
              portfolioPageDisabled
                ? `/${project.slug}`
                : `/project/${project.slug}`
            "
            >{{ project.name }}</a
          >
        </div>
        <div class="separator" v-if="projects.length > 1"></div>
        <router-link
          active-class="active"
          class="sidebar-link"
          tag="a"
          :to="{ name: !getSkipBuildingInit ? 'Home' : 'Space' }"
          >Home</router-link
        >
        <!--        <div class="hidden-lg">-->
        <!--          <router-link active-class="active" class="sidebar-link" tag="a" v-for="(section, i) in sections" v-if="section.enabled" :to="{name: 'SpecificProject', params: {projectId: activeProject}, hash: `#${section.hashAnchor}`}" :key="i">-->
        <!--            {{ section.menuLabel }}-->
        <!--          </router-link>-->
        <!--        </div>-->

        <div class="hidden-lg" v-if="containsSpaceSlug">
          <router-link
            active-class="active"
            class="sidebar-link"
            tag="a"
            v-for="(section, i) in sections"
            v-if="allowSection(section)"
            :to="{
              name: 'SpecificSpace',
              params: { projectId: activeProject, spaceId: activeSpace },
              hash: `#${section.hashAnchor}`,
            }"
            :key="i"
            @click.native="hashChecker(`#${section.hashAnchor}`)"
          >
            {{ section.menuLabel }}
          </router-link>
        </div>
        <div class="hidden-lg" v-else>
          <router-link
            active-class="active"
            class="sidebar-link"
            tag="a"
            v-for="(section, i) in sections"
            v-if="allowSection(section)"
            :to="{
              name: pageName,
              params: { projectId: activeProject },
              hash: `#${section.hashAnchor}`,
            }"
            :key="i"
            @click.native="hashChecker(`#${section.hashAnchor}`)"
          >
            {{ section.menuLabel }}
          </router-link>
        </div>

        <router-link
          v-for="(page, i) in staticPages"
          :key="i"
          active-class="active"
          class="sidebar-link"
          tag="a"
          :to="{ name: page.name, params: {} }"
          >{{ page.menuLabel || page.name }}</router-link
        >
        <div v-for="(page, i) in projectSpecificStaticPages" :key="i">
          <router-link
            v-if="!externalLink(page.path)"
            active-class="active"
            class="sidebar-link"
            tag="a"
            :to="{ name: page.name, params: { projectId: activeProject } }"
            >{{ page.menuLabel || page.name }}</router-link
          >
          <a v-else class="sidebar-link" :href="page.path">{{
            page.menuLabel || page.name
          }}</a>
        </div>
      </div>
      <div
        class="social-links"
        v-if="facebookUrl || instagramUrl || linkedinUrl"
      >
        <a :href="instagramUrl"
          ><AtomIcon icon="instagram" :size="24" color="#000"
        /></a>
        <a :href="linkedinUrl"
          ><AtomIcon icon="linkedin-squared" :size="24" color="#000"
        /></a>
        <a :href="facebookUrl"
          ><AtomIcon icon="facebook-squared" :size="24" color="#000"
        /></a>
      </div>
    </OrganismSidebar>
    <OrganismMenuBarProject>
      <div class="hidden-xs" v-if="containsSpaceSlug">
        <router-link
          active-class="active"
          class="sidebar-link navigation-link"
          tag="a"
          v-for="(section, i) in sections"
          v-if="allowSection(section)"
          :to="{
            name: 'SpecificSpace',
            params: { projectId: activeProject, spaceId: activeSpace },
            hash: `#${section.hashAnchor}`,
          }"
          :key="i"
          @click.native="hashChecker(`#${section.hashAnchor}`)"
        >
          {{ section.menuLabel }}
        </router-link>
      </div>
      <div class="hidden-xs" v-else>
        <router-link
          active-class="active"
          class="sidebar-link navigation-link"
          tag="a"
          v-for="(section, i) in sections"
          v-if="allowSection(section)"
          :to="{
            name: pageName,
            params: { projectId: activeProject },
            hash: `#${section.hashAnchor}`,
          }"
          :key="i"
          @click.native="hashChecker(`#${section.hashAnchor}`)"
        >
          {{ section.menuLabel }}
        </router-link>
      </div>
    </OrganismMenuBarProject>
    <OrganismTutorial />
  </div>
</template>

<script>
import OrganismSidebar from "../components/organisms/OrganismSidebar";
import OrganismMenuBarProject from "../components/organisms/OrganismMenuBarProject";
import { smoothScrollToTargetId } from "../helpers/util";
import AtomIcon from "@/components/atoms/common/AtomIcon";
import OrganismTutorial from "@/components/organisms/project/building/OrganismTutorial";

export default {
  name: "ProjectTemplate",
  components: {
    OrganismTutorial,
    AtomIcon,
    OrganismMenuBarProject,
    OrganismSidebar,
  },
  data() {
    return {
      sections: [],
    };
  },
  computed: {
    pageName() {
      const { name } = this.$route;
      return name;
    },
    projects() {
      return this.$store.state.project.projects;
    },
    landingSections() {
      return this.$store.state.base.meta.landing.sections;
    },
    sectionsData() {
      const { page } = this.$route.meta;
      return page ? page.sections : [];
    },
    activeProject() {
      return this.$store.state.project.activeProject;
    },
    activeSpace() {
      return this.$route.params.spaceId;
    },
    containsSpaceSlug() {
      return this.$route.params.spaceId !== undefined;
    },
    generalConfig() {
      return this.$store.state.base.meta.general;
    },
    instagramUrl() {
      if (!this.generalConfig.social) return "";
      return this.generalConfig.social.instagramUrl;
    },
    linkedinUrl() {
      if (!this.generalConfig.social) return "";
      return this.generalConfig.social.linkedinUrl;
    },
    facebookUrl() {
      if (!this.generalConfig.social) return "";
      return this.generalConfig.social.facebookUrl;
    },
    portfolioPageDisabled() {
      return this.generalConfig.portfolioPageDisabled || false;
    },
    staticPages() {
      return this.generalConfig.staticPages || [];
    },
    projectSpecificStaticPages() {
      return this.generalConfig.projectStaticPages || [];
    },
    getSkipBuildingInit() {
      return this.$store.getters.getSkipBuildingInit;
    },
  },
  watch: {
    $route: {
      deep: true,
      handler: function (to, from) {
        this.sortSections();
      },
    },
  },
  mounted() {
    this.sortSections();
    const self = this;
    document
      .querySelectorAll('a.navigation-link[href*="#"]')
      .forEach((anchor) => {
        anchor.addEventListener("click", self.smoothScroll);
      });
    if (this.$route.hash !== "") {
      document.querySelector(this.$route.hash).scrollIntoView({
        behavior: "smooth",
      });
    }
  },
  beforeDestroy() {
    const self = this;
    document
      .querySelectorAll('a.navigation-link[href*="#"]')
      .forEach((anchor) => {
        anchor.removeEventListener("click", self.smoothScroll);
      });
  },
  methods: {
    allowSection(section) {
      if (section.enabled === false || section.menuLabel === "") {
        // When the section is not active or the section has no text to display in the menu
        return false;
      }
      if (section.enabled === true && section.multi_project === false) {
        // When the section is active and we have no more projects
        return true;
      }
      if (section.enabled === true && section.multi_project === true) {
        // When the section is active and we have several projects
        let found = null;
        for (const [key, value] of Object.entries(section.data)) {
          if (key === this.activeProject) {
            found = value;
          }
        }
        if (found) {
          if (found.enabled === false) {
            // When on the building we have set enabled: false
            return false;
          }
          if (found.enabled === undefined || found.enabled === true) {
            // When on the building we do not have the enabled field in the config or when you set on the building enabled: true
            return section.enabled;
          }
        } else {
          // When a building/project is not found, although multiproject is active
          return false;
        }
      }
      return true;
    },
    externalLink(url) {
      if (url && url.includes("://")) {
        return true;
      }
      return false;
    },
    sortSections() {
      let sectionsData = [...this.sectionsData].map((s) => {
        const clone = { ...s };
        if (this.$route.name === "SpecificSpace" && clone.type === "Building")
          clone.order_no = 1;
        if (
          this.$route.name === "SpecificSpace" &&
          ["home", "hero"].includes(clone.hashAnchor)
        )
          clone.order_no = 2;
        return clone;
      });
      if (this.$route.name === "SpecificSpace") {
        sectionsData = sectionsData.filter(
          (s) => !["home", "hero"].includes(s.hashAnchor)
        );
      }
      this.sections = sectionsData.sort((a, b) => {
        if (a.order_no < b.order_no) {
          return -1;
        }
        if (a.order_no > b.order_no) {
          return 1;
        }
        return 0;
      });
    },
    smoothScroll(e) {
      e.preventDefault();
      document
        .querySelector(`#${e.target.getAttribute("href").split("#")[1]}`)
        .scrollIntoView({
          behavior: "smooth",
        });

      const links = this.$el.querySelectorAll('a.navigation-link[href*="#"]');
      links.forEach((link) => {
        const activeLink = e.path[0];
        link.classList.remove("active");
        if (link === activeLink && !activeLink.classList.contains("active")) {
          link.classList.add("active");
        }
      });
    },
    hashChecker(target) {
      // fix weird behaviour for scrolling event on second router-link click
      if (target === window.location.hash) {
        smoothScrollToTargetId(target);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.project-template {
  display: block;
}
</style>
